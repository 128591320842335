import React from 'react';
import './project.css' 
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';

class Project extends React.Component {
    render() {
      return (
        <Grid item key={this.props.projectKey} xs={12} sm={6} md={4}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' , backgroundColor:'rgb(200,250,255)'}}
                  
                >
                  <CardContent sx={{ flexGrow: 1 }} >
                    <Typography gutterBottom variant="h5" component="h2" color="rgb(30,80,150)">
                      {this.props.projectName}
                    </Typography>
                    <Typography>
                      {this.props.projectDescription}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    {/* <Button size="small">Description</Button> */}
                    <Button size="small" href={this.props.projectDemo} target="_blank" variant="contained" disabled={this.props.isDisabled}>
                      {this.props.demoDescription}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
      );
    }
  }

  export default Project;