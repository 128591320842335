import React from 'react';
import './pages.css';
const Home = () => {
  return (
    <div className='specialDiv'
      // style={{
      //   display: 'flex',
      //   justifyContent: 'Center',
      //   alignItems: 'Center',
      //   height: '100vh',
      //   // background: 'rgb(42,100,213)',
      //   background: 'linear-gradient(105deg, rgba(42,100,213,1) 0%, rgba(2,8,89,1) 94%)',
      //   flexDirection: 'column',
      //   color: 'rgb(100,190,255)'
      // }}
    >
      {/* <div className='main-text' style={{flex: 1, paddingTop: 50, paddingBottom: 0}}> */}
        <h1>Hello there!</h1>
      {/* </div> */}
      {/* <div style={{flex: 2, paddingLeft: 50, paddingRight: 50, paddingBottom: 20}}> */}
        <h3>My name is Elie! Welcome to my little corner of the internet. This is my website, you're welcome to browse around! If you're with a company looking to hire me, I'd suggest going to the 'Resume' and the 'Projects' sections, but feel free to check out everything.</h3>
        {/* </div> */}
      
      
      
      
    </div>
  );
};
  
export default Home;