// import { FaBars } from 'react-icons/fa';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';
  
export const Nav = styled.nav`
  background: rgb(42,100,213);
  background: linear-gradient(90deg, rgba(42,100,213,1) 0%, rgba(2,8,89,1) 94%);
  height: 85px;
  width:  100%;
  display: flex;
  justify-content: space-between;
  padding: 0.2rem calc((100vw - 1000px) / 2);
  z-index: 12;
  border-bottom: 1px solid #4686FE;
  /* Third Nav */
  /* justify-content: flex-start; */
`;
  
export const NavLink = styled(Link)`
  color: #AAAADD;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-family: 'Nanum Gothic', sans-serif;
  font-weight: normal;
  padding: 0 2rem;
  height: 100%;
  width:  100%;
  cursor: pointer;
  &.active {
    color: #4686FE;
  }
`;

// export const NavText = styled.div`
//   color: #3377EE;
//   // display: flex;
//   // align-items: center;
//   // text-decoration: none;
//   // font-family: 'Nanum Gothic', sans-serif;
//   // font-weight: normal;
//   // padding: 0 1rem;
//   // height: 100%;
//   // cursor: pointer;
//   // &.active {
//   //   color: #4686FE;
//   // }
// `;

  
// export const Bars = styled(FaBars)`
//   display: none;
//   color: #2a64d5;
//   @media screen and (max-width: 768px) {
//     display: block;
//     position: absolute;
//     top: 0;
//     right: 0;
//     transform: translate(-100%, 75%);
//     font-size: 1.8rem;
//     cursor: pointer;
//   }
// `;
  
export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;
  /* Second Nav */
  margin-right: 24px;
  /* Third Nav */
  width: 100%;
  white-space: nowrap;
  // @media screen and (max-width: 768px) {
  //   display: none;
  // }
`;
  
// export const NavBtn = styled.nav`
//   display: flex;
//   align-items: center;
//   margin-right: 24px;
//   /* Third Nav */
//   justify-content: flex-end;
//   width: 100vw; 
//   @media screen and (max-width: 768px) {
//     display: none;
//   }
// `;
  
// export const NavBtnLink = styled(Link)`
//   border-radius: 4px;
//   background: #808080;
//   padding: 10px 22px;
//   color: #000000;
//   outline: none;
//   border: none;
//   cursor: pointer;
//   transition: all 0.2s ease-in-out;
//   text-decoration: none;
//   /* Second Nav */
//   margin-left: 24px;
//   &:hover {
//     transition: all 0.2s ease-in-out;
//     background: #fff;
//     color: #808080;
//   }
// `;