import * as React from 'react';
// import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
// import CameraIcon from '@mui/icons-material/PhotoCamera';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
// import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
// import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './pages.css';
import Project from '../components/Project/Project'


const theme = createTheme();

export default function Projects() {
  return (
    <div className='specialDiv'>
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <main>

        <Box
          sx={{
            // bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">
           
            <Typography variant="h5" align="center"  paragraph>
              Here are some of the projects I've worked on:
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
            </Stack>
          </Container>
        </Box>
        <Container sx={{ py: 8 }} maxWidth="md">
          
          <Grid container spacing={4}>

              <Project projectKey={1} projectName="elayus.com" projectDescription="This website. I've rented a Linode mini server, serving this website using an NGINX loadbalancer." demoDescription="Home (elayus.com)" projectDemo="/"></Project>

              <Project projectKey={2} projectName="E-Valet" projectDescription="Bachelor's project with Nabih Bassil. Valet Parking assistant app. User can upload map location, picture, description (plate number or color) to a Firebase cloud database, and retrieve them. B2B solution for valet parking in Lebanon in case no designated parking space is available."
              projectDemo="https://youtu.be/iLaEV0USuV4" demoDescription="Demo (Youtube)"></Project>
              <Project projectKey={3} projectName="Fakestocks" projectDescription="Website that polls stock prices, updates them, and the user has a set amount of starting money where he/she can 'fake' buy or sell stocks, check his balance, and check the distribution of his portfolio in charts, also check the stock price trend."  projectDemo="/" isDisabled demoDescription="N/A"></Project>
              
              <Project projectKey={4} projectName="Music Generation" projectDescription="Ongoing project with Marc Salvadó Benasco. Goal is to have the user record themselves, and from that recording we want to generate a full song. This will involve melody, beat, as well as lyric generation." projectDemo="/" isDisabled demoDescription="N/A"></Project>
              

          </Grid>
        </Container>
      </main>
      
    </ThemeProvider>
    </div>
  );
}