import React from 'react';
import {
  Nav,
  NavLink,
  // Bars,
  NavMenu,
  // NavBtn,
  // NavBtnLink,
  // NavText
} from './NavbarElements';
  
const Navbar = () => {
  return (
    <>
      <Nav>
        {/* <Bars onClick={() => alert("hello")}/> */}
  
        <NavMenu>
          
          <NavLink to='/' activestyle  end>
            Home
          </NavLink>

          <NavLink to='/resume' activestyle>
            Resume
          </NavLink>
          {/* <NavLink to='/events' activeStyle>
            Events
          </NavLink>
          <NavLink to='/annual' activeStyle>
            Annual Report
          </NavLink>
          <NavLink to='/team' activeStyle>
            Teams
          </NavLink> */}
          {/* <NavText>|</NavText> */}
          <NavLink to='/projects' activestyle>
          Projects
          </NavLink>

          <NavLink to='/advertisements' activestyle>
          Adspace
          </NavLink>
          {/* <NavLink to='/sign-up' activeStyle>
            Sign Up
          </NavLink> */}
          {/* Second Nav */}
          {/* <NavBtnLink to='/sign-in'>Sign In</NavBtnLink> */}
        </NavMenu>
        {/* <NavBtn>
          <NavBtnLink to='/signin'>Sign In</NavBtnLink>
        </NavBtn> */}
      </Nav>
    </>
  );
};
  
export default Navbar;