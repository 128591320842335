import React from 'react';
import onButtonClickDownloadPDF from '../utils/downloadPdf'
import Button from 'react-bootstrap/Button';

const Resume = () => {
  return (
    <div className='specialDiv'
      // style={{
      //   display: 'flex',
      //   justifyContent: 'Center',
      //   alignItems: 'Center',
      //   height: '100vh',
      //   width: '100vw', 
      //   // background: 'rgb(42,100,213)',
      //   background: 'linear-gradient(105deg, rgba(42,100,213,1) 0%, rgba(2,8,89,1) 94%)',
      // }}
    >

      <Button variant="dark" onClick={onButtonClickDownloadPDF}>Download Resume PDF</Button>
    </div>
  );
};
  
export default Resume;