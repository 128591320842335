import React from 'react';
import './pages.css';

const Ads = () => {
  return (
      <div className='specialDiv'>
        {/* // style={{
        // display: 'flex',
        // justifyContent: 'Right',
        // alignItems: 'Right',
        // height: '90vh',
        // background: 'rgb(42,100,213)',
        // background: 'linear-gradient(105deg, rgba(42,100,213,1) 0%, rgba(2,8,89,1) 94%)',
        // // backgroundImage: 'url("../tileable_circuit.png")',
        // // backgroundSize: '10rem',
        // // backgroundColor: 'transparent',
        // // backgroundRepeat:'repeat',
      // }} */}
    
    <div className="house-disappear"></div>
    </div>
  );
};
  
export default Ads;