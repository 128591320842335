import React from 'react';
import './App.css';
import Navbar from './components/Navbar/NavBar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages';
import Resume from './pages/resume';
import Projects from './pages/projects';
import Ads from './pages/advertisements';


function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path='/' exact element={<Home/>} />
        <Route path='/resume' element={<Resume/>} />
        <Route path='/projects' element={<Projects/>} />
        <Route path='/advertisements' element={<Ads/>} />
      </Routes>
    </Router>
    
  );
}
  
export default App;